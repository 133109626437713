import React from 'react';

import { t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import { type FieldErrors, type UseFormWatch } from 'react-hook-form';

import { UserAvatar } from '~/components/UserAvatar';
import { UserAvatarGroup } from '~/components/UserAvatar/UserAvatarGroup';

import { ColumnPosition, type IColumnTable } from '~/@types/table';
import { COLORS } from '~/styles';

import { ErrorIcon } from '../components/ErrorIcon';
import { JobsRowWithError, NameRow } from '../design';
import { errors as errorTypes } from '../hooks/useResolver';
import { type IEmployee, SORT_OPTIONS, IReviewCycleForm } from '../types';

import type { IMultiLangString } from '@learned/types';
import type { I18n } from '@lingui/core';

export const createColumns = ({
  getMultiLangString,
  i18n,
  options,
  errors,
  watch,
}: {
  getMultiLangString: (multiLangString: IMultiLangString) => string;
  i18n: I18n;
  options?: {
    showCoaches?: boolean;
    showJobs?: boolean;
  };
  errors?: FieldErrors<IReviewCycleForm>;
  watch: UseFormWatch<IReviewCycleForm>;
}): IColumnTable<IEmployee & { index: number }>[] => [
  {
    name: 'Employees',
    accessor: 'name',
    maxWidth: '180px',
    renderCell: (item, _onClick, isHovered, isSelected) => {
      const guestsError = errors?.employees?.[item.index]?.guests;
      const errorMessage =
        guestsError?.type === errorTypes.noQuestions ? guestsError?.message : undefined;
      return (
        <JobsRowWithError>
          <UserAvatar
            userId={item.id}
            options={{
              customColor: isSelected
                ? COLORS.COMPANY
                : isHovered
                ? COLORS.TEXT_HOVER
                : COLORS.TEXT_MAIN,
              showTooltip: true,
            }}
          />
          {guestsError && (
            <ErrorIcon
              watch={watch}
              jobs={item.jobProfiles}
              errors={{
                custom: errorMessage,
              }}
            />
          )}
        </JobsRowWithError>
      );
    },
    sortBy: {
      asc: {
        key: SORT_OPTIONS.NAME_A_Z,
        label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
      },
      desc: {
        key: SORT_OPTIONS.NAME_Z_A,
        label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
      },
    },
    isFixed: true,
    position: ColumnPosition.LEFT,
  },
  {
    name: 'Jobs',
    accessor: 'role',
    renderCell: (item) => {
      const firstProfile =
        item.jobProfiles && item.jobProfiles.length > 0 ? item.jobProfiles[0] : null;
      const firstJobProfileName =
        firstProfile && firstProfile.name ? getMultiLangString(firstProfile.name) : '';
      const hasErrors = errors?.employees && errors.employees[item.index]?.jobProfiles;
      const moreJobs = item.jobProfiles.length - 1;
      const jobProfileError = errors?.employees?.[item.index]?.jobProfiles;

      return (
        <>
          {item.jobProfiles?.length > 0 ? (
            hasErrors ? (
              <JobsRowWithError>
                <NameRow>
                  {firstJobProfileName} {moreJobs > 0 ? `+ ${moreJobs}` : ''}
                </NameRow>
                <ErrorIcon
                  watch={watch}
                  jobs={item.jobProfiles}
                  errors={{
                    noFocusArea:
                      jobProfileError?.type === errorTypes.noFocusArea
                        ? jobProfileError?.message
                        : undefined,
                    noSkill:
                      jobProfileError?.type === errorTypes.noSkill
                        ? jobProfileError?.message
                        : undefined,
                  }}
                />
              </JobsRowWithError>
            ) : (
              <NameRow>
                {firstJobProfileName} {moreJobs > 0 ? `+ ${moreJobs}` : ''}
              </NameRow>
            )
          ) : (
            <NameRow color={hasErrors ? COLORS.ACCENT_WARNING : COLORS.PLACEHOLDERS}>
              {i18n._(t`No job`)}
            </NameRow>
          )}
        </>
      );
    },
    isHidden: !options?.showJobs,
  },
  {
    name: 'Coaches Providing Review',
    accessor: 'coaches',
    renderCell: (item) => {
      return isEmpty(item.coaches) ? (
        // @ts-ignore
        <NameRow
          color={
            errors?.employees && errors?.employees[item.index]?.coaches
              ? COLORS.ACCENT_WARNING
              : COLORS.PLACEHOLDERS
          }
        >
          {i18n._(t`No coaches`)}
        </NameRow>
      ) : (
        <UserAvatarGroup users={item.coaches.map((userId) => ({ userId }))} />
      );
    },
    thTooltip: i18n._(t`Coaches who will be selected to give coach review`),
    isHidden: !options?.showCoaches,
  },
  {
    name: 'Guests',
    accessor: 'guests',
    renderCell: (item) => {
      return isEmpty(item.guests) ? (
        <NameRow color={COLORS.PLACEHOLDERS}>{i18n._(t`No guests`)}</NameRow>
      ) : (
        <UserAvatarGroup users={item.guests.map((userId) => ({ userId }))} />
      );
    },
    thTooltip: i18n._(t`Guests are invited but will not provide a review`),
    maxWidth: '100%',
  },
];
