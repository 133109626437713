import { createSelector } from 'reselect';

export const getApp = (state) => state.app;
export const getIsRightSideBarOpen = createSelector([getApp], (app) => app.isRightSideBarOpen);
export const getMaintenanceTitle = createSelector([getApp], (app) => app.maintenanceTitle);
export const getMaintenanceBody = createSelector([getApp], (app) => app.maintenanceBody);
// Selector for getting the isSecondaryMenuOpen state
// This selector is used to determine if the secondary menu is open or not
// The secondary menu is the menu that appears when the user clicks primary menu items(teams, reports, job-matrix)
export const getIsSecondaryMenuOpen = createSelector([getApp], (app) => app.isSecondaryMenuOpen);
