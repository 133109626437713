import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { ROLES } from '~/constants';
import { getSelectedRole } from '~/selectors/baseGetters';
import { updateApp } from '~/store/app/actions';
import { updateSelectedRole, updateSelectedTeam } from '~/store/selected/actions';

import { MENU_ITEMS } from '../constants';
import MenuItemBlock from '../MenuItemBlock';
import { MenuDivider } from '../styles/design';
import { IMenuItem } from '../types';
import { menuBlockHighlighted } from '../utils';
interface IPrimaryMenuItemProps {
  item: IMenuItem;
  isMenuCollapsed: boolean;
  isSmallScreenHeight?: boolean;
}

const PrimaryMenuItem = ({
  item: menuBlock,
  isMenuCollapsed,
  isSmallScreenHeight,
}: IPrimaryMenuItemProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedRole = useSelector(getSelectedRole);
  const { pathname } = location;

  const isCurrent = menuBlockHighlighted(menuBlock, pathname, selectedRole as string);

  const handleMenuItemClick = () => {
    // @ts-ignore
    dispatch(updateSelectedRole(menuBlock?.children[0]?.role));
    // @ts-ignore
    if (menuBlock.children[0].role === ROLES.COACH) {
      // @ts-ignore
      dispatch(updateSelectedTeam(menuBlock?.children[0]?.team));
    }
    // let the secondary menu open, when clicking on primary menu item
    // it will open only if there is secondary menu items
    dispatch(updateApp({ isSecondaryMenuOpen: true }));
  };

  return (
    <>
      {!isCurrent && isMenuCollapsed && menuBlock?.key === MENU_ITEMS.ACCOUNT && (
        <MenuDivider isNoMargins />
      )}
      <MenuItemBlock
        item={menuBlock}
        isMenuCollapsed={isMenuCollapsed}
        isShowTooltip={isMenuCollapsed}
        onLinkClick={handleMenuItemClick}
        isCurrent={isCurrent}
        // @ts-ignore
        linkTo={menuBlock?.children[0]?.url}
        isSmallScreenHeight={isSmallScreenHeight}
      />
    </>
  );
};

export default PrimaryMenuItem;
