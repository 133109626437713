import styled from 'styled-components';

import { COLORS } from '~/styles';

const Container = styled.div<{ showControlPanel: boolean }>`
  width: ${({ showControlPanel }) => (showControlPanel ? '270px' : '60px')};
  font-size: 14px;
  flex: none;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  background: ${COLORS.WHITE};
  box-sizing: border-box;
`;

const Header = styled.div`
  padding: 0 24px;
  border-bottom: 1px solid ${COLORS.BORDERS};
  display: flex;
  gap: 6px;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  cursor: pointer;
  & > .checkbox-open {
    transform: rotate(180deg);
  }
`;

const Inner = styled.div<{ isDisabled: boolean }>`
  padding: 20px 24px 0;
  box-sizing: border-box;
  overflow-y: hidden;
  height: calc(100vh - 50px);
  flex: auto;
  position: relative;
  &::after {
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'auto')};
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: ${({ isDisabled }) => (isDisabled ? 'block' : 'none')};
    opacity: 0.8;
    background-color: ${COLORS.WHITE};
    z-index: 9999;
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLORS.BG_ELEMENTS};
  border-radius: 6px;
  border: 1px solid ${COLORS.BORDERS};
  padding: 4px 5px;
`;

const InnerTab = styled.div<{ isSelected: boolean }>`
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  background-color: ${({ isSelected }) => (isSelected ? COLORS.WHITE : COLORS.BG_ELEMENTS)};
  border-radius: 6px;
  cursor: pointer;
  color: ${({ isSelected }) => (isSelected ? COLORS.TEXT_HOVER : COLORS.SUBTEXT)};
`;

const TabBody = styled.div`
  box-sizing: border-box;
  padding: 20px 20px 20px 0;
  height: calc(100vh - 120px);
  overflow-y: auto;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const SectionHead = styled.div`
  color: ${COLORS.TEXT_HOVER};
  font-weight: 600;
`;

const DropDownOuter = styled.div<{ enabled: boolean }>`
  opacity: ${({ enabled }) => (enabled ? 1 : 0.4)};
  pointer-events: ${({ enabled }) => (enabled ? 'auto' : 'none')};
`;

const SectionBody = styled.div<{ gap: string }>`
  padding-top: 13px;
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
`;

const SectionBodySingleLine = styled.div`
  display: inline;
  & > a {
    margin-left: 5px;
  }
`;

const ResetFilters = styled.div<{ enabled: boolean }>`
  color: ${COLORS.SUBTEXT};
  border-left: 1px solid ${COLORS.BORDERS};
  padding-left: 12px;
  margin-top: 10px;
  cursor: pointer;
  opacity: ${({ enabled }) => (enabled ? 1 : 0.4)};
  pointer-events: ${({ enabled }) => (enabled ? 'auto' : 'none')};
`;

const DropDownCtr = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  &.disabled {
    opacity: 0.4;
  }
`;

const CheckBoxOutCtr = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const CheckBoxCtr = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  user-select: none;
  font-size: 14px;
  cursor: pointer;
  & > .checkbox {
    cursor: pointer;
    flex: none;
  }
  &.disabled {
    opacity: 0.4;
  }
`;

const Label = styled.div`
  color: ${COLORS.TEXT_HOVER};
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${COLORS.BORDERS};
  margin: 20px 0;
`;

const VerticalTab = styled.div`
  writing-mode: vertical-rl;
  text-orientation: mixed;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0;
  cursor: pointer;
  box-sizing: border-box;
`;

export {
  Container,
  Header,
  Inner,
  Tabs,
  InnerTab,
  TabBody,
  Section,
  SectionHead,
  DropDownOuter,
  SectionBody,
  SectionBodySingleLine,
  ResetFilters,
  DropDownCtr,
  CheckBoxOutCtr,
  CheckBoxCtr,
  Label,
  Separator,
  VerticalTab,
};
