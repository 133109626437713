import { ROLES } from '@learned/constants';
import find from 'lodash/find';
import flatMapDeep from 'lodash/flatMapDeep';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { matchPath } from 'react-router';

import type { IMenuItem, IMenuItemChild } from '../types';
import type { IConnection, IUser, ITeam } from '@learned/types';
export interface UserWithConnections extends IUser {
  connections?: IConnection[];
}

export const isUserWithCoachRole = (user: UserWithConnections, selectedCompany: string) => {
  const connections = user?.connections || [];
  const activeCompany = selectedCompany || connections[0]?.company;
  const selectedCompanyConnection = connections.find((c) => c.company === activeCompany);
  return (selectedCompanyConnection?.roles || []).includes(ROLES.COACH);
};

export const getCoachTeamsForUser = (
  user: UserWithConnections,
  teams: Record<string, ITeam>,
  selectedCompany: string,
) => {
  const connections = user?.connections || [];
  const teamsArray = (teams && Object.values(teams)) || [];
  const allCoachTeams = teamsArray.filter(
    (team) =>
      connections.some(
        (conn: IConnection) =>
          team.company === conn.company && (conn.roles || []).includes(ROLES.COACH),
      ) && team.coaches.includes(user.id),
  );
  const activeCompany = selectedCompany || connections[0]?.company;
  const companyCoachTeams = allCoachTeams.filter((team) => team.company === activeCompany);
  return sortBy(Object.values(companyCoachTeams) || [], (team) => team.name.toLowerCase());
};

export const getUrlWithoutQuery = (url = '') => {
  const urlArr = url.split('?');
  return urlArr.length > 0 ? urlArr[0] : '';
};

export const isUserAdminInSelectedCompany = (
  selectedCompany: string,
  connections: IConnection[] = [],
) => {
  const selectedCompanyObj =
    selectedCompany && connections.find((c) => c.company === selectedCompany);
  if (selectedCompanyObj) {
    return selectedCompanyObj?.roles.includes(ROLES.ADMIN);
  }

  if (!selectedCompany) {
    return connections[0]?.roles.includes(ROLES.ADMIN);
  }

  return false;
};

const isPathMatch = (pathname?: string, url?: string) => {
  if (!pathname || !url) {
    return false;
  }

  return Boolean(
    matchPath(pathname, {
      path: url,
      strict: true, // https://v5.reactrouter.com/core/api/Route/strict-bool
      exact: true, // https://v5.reactrouter.com/core/api/Route/exact-bool
    }),
  );
};

const isSomePathMatch = (pathname?: string, urls?: string[]) => {
  if (!urls || !pathname || isEmpty(urls)) {
    return false;
  }

  return urls.some((url) => isPathMatch(pathname, url));
};

export function searchMenuItem(item: IMenuItem | IMenuItemChild, pathname: string) {
  if (isPathMatch(pathname, item?.url || '') || isSomePathMatch(pathname, item?.aliases)) {
    return item;
  }

  const searched = find(
    // @ts-ignore
    (item?.children || []).map((child: IMenuItemChild) => searchMenuItem(child, pathname)),
    (i) => i,
  );
  if (searched) {
    return item;
  }
}

export const findMenuBlock = (
  menuStructure: IMenuItem[] | IMenuItemChild[],
  pathname: string,
): IMenuItem | undefined => {
  let block;
  menuStructure.forEach((menuBlock) => {
    if (
      // @ts-ignore
      (menuBlock?.children || []).some((secondLevel: IMenuItem | IMenuItemChild) =>
        searchMenuItem(secondLevel, pathname),
      )
    ) {
      block = menuBlock;
    }
  });
  return block;
};

const getAllChildrenMenuItems = (menuItems: IMenuItem[]): IMenuItem[] => {
  return flatMapDeep(menuItems, (item: IMenuItem) => [
    item,
    ...getAllChildrenMenuItems((item.children || []) as IMenuItem[]),
  ]);
};

export const searchInChild = (menuBlock: IMenuItem, pathname: string) => {
  const allChildMenuItems = getAllChildrenMenuItems([menuBlock]);
  const allChildMenuUrls = allChildMenuItems.map((item) => item.url).filter(Boolean);
  return menuBlock?.children?.some(
    (x) =>
      // @ts-ignore
      x.children &&
      // @ts-ignore
      x.children.some(
        // @ts-ignore
        (y) =>
          isPathMatch(pathname, y?.url) ||
          isSomePathMatch(pathname, y?.aliases) ||
          allChildMenuUrls.includes(pathname),
      ),
  );
};

export const menuBlockHighlighted = (
  menuBlock: IMenuItem,
  pathname: string,
  selectedRole: string,
) => {
  const directChild = menuBlock.children?.some(
    (c) =>
      // @ts-ignore
      isPathMatch(pathname, c?.url) || isSomePathMatch(pathname, c?.aliases),
  );
  const grandChild = searchInChild(menuBlock, pathname);
  const isUserPublic =
    pathname.includes('user-public') &&
    ((selectedRole === ROLES.ADMIN && menuBlock.key === 'admin-menu') ||
      (selectedRole === ROLES.COACH && menuBlock.key === 'coach-menu'));
  return directChild || grandChild || isUserPublic;
};
